import { ArticleErrors } from '@keplr/api-ecom-errors';

/**
 * Erreur E_ECOM_16_0001 - Le produit n'existe plus
 * Le produit n'existe plus dans le catalogue produit
 * Comportement: L'utilisateur est redirigé vers la homepage
 */

export default {
  code: ArticleErrors.ArticleNotFound.code,
  handler: () => {
    window.location.assign('/');
  },
};
