import { AuthErrors } from '@keplr/api-ecom-errors';

import Dialog from '~/src/common/components/Dialog';
import I18n from '~/src/common/services/I18n';

/**
 * Erreur E_ECOM_02_0013 - Le token fourni dans l'email de réinitialisation de mot de passe a expiré
 * Apparaît quand le token fourni dans l'email de réinitialisation de mot de passe a expiré
 * Comportement : On affiche un message à l'utilisateur spécifique
 */

export default {
  code: AuthErrors.TokenExpired.code,
  handler: () => {
    Dialog.error({
      title: I18n.t('errors.default-title'),
      description: I18n.t('reset-password-modal.expired-token'),
    });
  },
};
