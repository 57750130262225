import { useCallback, useRef } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { CartErrors } from '@keplr/api-ecom-errors';

import { logger } from '~/src/common/services/Logger';
import { ModalName, useModalState } from '~/src/common/services/ModalsManager';
import { isApiEcomError } from '~/src/common/utils/guards';
import { ApiEcomError } from '~/src/queries/services/client';

import Dialog from '../../components/Dialog';
import I18n from '../I18n';

import businessErrorsHandlers, { HandlerPayload } from './business-error/business-errors-handlers';

export const handleApiEcomClientError = (payload: HandlerPayload) => {
  const { code } = payload.error;

  const { message } = payload.error;

  logger.info(`${code} - ${message}`);

  const isHandled = code && businessErrorsHandlers[code] != null;

  if (isHandled) {
    const businessErrorHandler = businessErrorsHandlers[code];

    businessErrorHandler(payload);
  } else {
    Dialog.error({
      title: I18n.t('errors.default-title'),
      description: message,
    });
  }
};

export const useApiEcomClientError = () => {
  const queryClient = useQueryClient();
  const modalsState = useModalState();

  const modalsStateRef = useRef<Partial<Record<ModalName, boolean>>>();
  modalsStateRef.current = modalsState;

  const handleError = useCallback(
    (error: ApiEcomError) => {
      handleApiEcomClientError({
        error,
        store: { queryClient, modalsState: modalsStateRef.current },
      });
    },
    [queryClient],
  );

  return handleError;
};

const UNHANDLED_ADD_TO_CART_ERROR_CODES = [
  CartErrors.ProductOutOfStock.code,
  CartErrors.ProductQuantityUnavailable.code,
  CartErrors.ProductNotFound.code,
];

export const useHandleFirstAddToCartError = () => {
  const handleError = useApiEcomClientError();
  const handler = useCallback(
    (error: unknown) => {
      if (isApiEcomError(error)) {
        if (UNHANDLED_ADD_TO_CART_ERROR_CODES.includes(error.code)) return;

        handleError(error);
      }
    },
    [handleError],
  );

  return handler;
};
