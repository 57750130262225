import { QueryClient } from '@tanstack/react-query';

import { ModalState } from '~/src/common/services/ModalsManager';
import { ApiEcomError } from '~/src/queries/services/client';

import ProductDoesNotExistAnymore from './article/E_ECOM_16_0001';
import Unauthorized from './auth/E_ECOM_02_0002';
import ResetPasswordTokenExpired from './auth/E_ECOM_02_0013';
import OrderNoMoreCompletable from './cart/E_ECOM_01_0008';
import InvalidCoupon from './coupon/E_ECOM_15_0002';
import InvalidCustomerId from './customers/E_ECOM_06_0006';
import TimeSlotExcluded from './delivery/E_ECOM_12_0002';
import EmptyCart from './orders/E_08_0004';
import CartNotFound from './orders/E_08_0005';
import InvalidTimeSlot from './orders/E_ECOM_08_0003';
import TimeSlotDeleted from './orders/E_ECOM_08_0008';
import InvalidPromoCode from './promo-code/E_ECOM_15_0001';
import InvalidReferral from './referral/E_ECOM_13_0002';

export interface HandlerPayload {
  error: ApiEcomError;
  store?: {
    queryClient: QueryClient;
    modalsState?: ModalState;
  };
}

type BusinessErrorsHandlers = {
  [errorCode: string]: (payload: HandlerPayload) => void;
};

const businessErrorsHandlers: BusinessErrorsHandlers = {
  // Auth
  [Unauthorized.code]: Unauthorized.handler,
  [ResetPasswordTokenExpired.code]: ResetPasswordTokenExpired.handler,

  // Orders
  [InvalidTimeSlot.code]: InvalidTimeSlot.handler,
  [TimeSlotDeleted.code]: TimeSlotDeleted.handler,
  [EmptyCart.code]: EmptyCart.handler,

  // PIM
  [ProductDoesNotExistAnymore.code]: ProductDoesNotExistAnymore.handler,

  // Customer
  [InvalidCustomerId.code]: InvalidCustomerId.handler,

  // Coupons
  [InvalidCoupon.code]: InvalidCoupon.handler,

  // Promocodes
  [InvalidPromoCode.code]: InvalidPromoCode.handler,

  // Referral
  [InvalidReferral.code]: InvalidReferral.handler,

  // Delivery
  [TimeSlotExcluded.code]: TimeSlotExcluded.handler,

  // Cart
  [OrderNoMoreCompletable.code]: OrderNoMoreCompletable.handler,
  [CartNotFound.code]: CartNotFound.handler,
};

export default businessErrorsHandlers;
