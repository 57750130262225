import { ReferralErrors } from '@keplr/api-ecom-errors';

import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import { showNiceModal } from '~/src/common/services/ModalsManager';

export default {
  code: ReferralErrors.Anonymous.code,
  handler: (payload: HandlerPayload) => {
    const { referralCode } = payload.error.meta || {};

    void showNiceModal('account-management-modal', {
      referralCode: referralCode as string,
      showSignup: true,
    });
  },
};
