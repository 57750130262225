import { AuthErrors } from '@keplr/api-ecom-errors';

import Dialog from '~/src/common/components/Dialog';
import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import I18n from '~/src/common/services/I18n';
import { postApiAuthSignout } from '~/src/queries/api-ecom/generated/api-ecom';
import { ApiEcomError } from '~/src/queries/services/client';
import { handleSignoutSuccess } from '~/src/screens/App/queries/user';

/**
 * Erreur E_ECOM_02_0002 - Le token fourni a expiré
 * Apparaît quand la session a expiré ou n'est plus valide
 * Comportement : On affiche un message à l'utilisateur et on le deconnecte
 */

export default {
  code: AuthErrors.Unauthorized.code,
  handler: ({ store }: HandlerPayload) => {
    Dialog.error({
      title: I18n.t('errors.default-title'),
      description: I18n.t('errors.E_01_0000'),

      onCancel: async () => {
        try {
          await postApiAuthSignout();

          if (store?.queryClient) {
            handleSignoutSuccess(store?.queryClient);
          }
        } catch (error: unknown) {
          const typedError = error as ApiEcomError;

          Dialog.error({
            title: I18n.t('errors.default-title'),
            description: typedError.message,
          });
        }
      },
    });
  },
};
