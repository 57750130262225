import React from 'react';

import { CouponErrors } from '@keplr/api-ecom-errors';

import Dialog from '~/src/common/components/Dialog';
import UnavailableCouponReasonsList from '~/src/common/components/UnavailableCouponDialog/UnavailableCouponDialog';
import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import I18n from '~/src/common/services/I18n';
import { isString } from '~/src/common/utils/guards';

/**
 * Erreur E_ECOM_15_0001 - Le code promo a déjà été appliqué
 * Lors de l'application d'un code promo, le code promo a déjà été appliqué au cart
 */

export default {
  code: CouponErrors.AlreadyUsed.code,
  handler: ({ error }: HandlerPayload) => {
    const { message } = error;

    const promoCode = error.meta?.promoCodeId;

    if (message && isString(promoCode)) {
      const { promoCodeId } = error.meta || {};

      Dialog.error({
        title: I18n.translate({
          value: 'checkout-screen.discount-section.unavailable-code-promo-coupons-title',
          promoCode,
        }),
        description: (
          <UnavailableCouponReasonsList
            reasons={[message.replace(promoCodeId as string, promoCode)]}
          />
        ),
      });
    } else {
      Dialog.error({
        title: I18n.t('errors.default-title'),
        description: message,
      });
    }
  },
};
