import { OrderErrors } from '@keplr/api-ecom-errors';

import invalidTimeSlotHandler from './E_ECOM_08_0003';

/**
 * Erreur E_ECOM_08_0008 - Le créneau de livraison n'est plus valide
 * Le créneau de livraison n'est plus valide et ne peut donc plus être utilisé (supprimé/modifié/expiré)
 * Comportement: On invite l'utilisation sélectionner une adresse de livraison (si besoin) et un nouveau créneau
 * et on met à jour le panier si des données concernant ce dernier on été renvoyées dans la réponse
 */

export default {
  code: OrderErrors.TimeSlotDeleted.code,
  handler: invalidTimeSlotHandler.handler,
};
