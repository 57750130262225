import { LegacyCartErrors } from '@keplr/api-ecom-errors';

import { noop } from '~/src/common/utils/function';

/**
 * Erreur E_08_0004 - Le panier est introuvable
 * Le panier est introuvable
 * Comportement:
 * On ne fait rien, on affiche donc pas de message d'erreur mais on affiche le panier vide par exemple
 */

export default {
  code: LegacyCartErrors.CartNotFound.code,
  handler: noop,
};
