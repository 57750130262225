import React from 'react';

import { CouponErrors } from '@keplr/api-ecom-errors';

import Dialog from '~/src/common/components/Dialog';
import UnavailableCouponReasonsList from '~/src/common/components/UnavailableCouponDialog/UnavailableCouponDialog';
import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import I18n from '~/src/common/services/I18n';
import { isObject, isString } from '~/src/common/utils/guards';

/**
 * Erreur E_ECOM_15_0002 - La création du coupon a échoué
 * Lors de l'application d'un code promo, la création des coupons a echoué du fait :
 * - Que le code promo n'existe pas
 * - Que les règles du code promo ne sont pas remplies
 * - Que les règles du coupon ne sont pas remplies
 */

export default {
  code: CouponErrors.CouponCreationFailed.code,
  handler: ({ error }: HandlerPayload) => {
    const { reason, errorMessages } = error.meta || {};

    if (
      (reason === 'notFound' || reason === 'rulesDidntMatch') &&
      errorMessages != null &&
      Array.isArray(errorMessages)
    ) {
      const ErrorDescription =
        reason === 'notFound' ? (
          <span>{errorMessages[0]}</span>
        ) : (
          <UnavailableCouponReasonsList reasons={errorMessages} />
        );

      Dialog.error({
        title: I18n.translate({
          value: 'checkout-screen.discount-section.unavailable-code-promo-coupons-title',
          promoCode:
            isObject(error.meta) && isString(error.meta.promoCode) ? error.meta.promoCode : '',
        }),
        description: ErrorDescription,
      });
    } else {
      Dialog.error({
        title: I18n.t('errors.default-title'),
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
        description:
          errorMessages != null && Array.isArray(errorMessages) && errorMessages[0]
            ? errorMessages[0]
            : I18n.t('checkout-screen.discount-section.error-code-promo'),
      });
    }
  },
};
