import { CartErrors } from '@keplr/api-ecom-errors';

import { HandlerPayload } from '~/src/common/services/error-handler/business-error/business-errors-handlers';
import { showNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import { Cart } from '~/src/common/typings/cart';
import { getTimeslotIntervalHourLabelInfos } from '~/src/common/utils/date';
import { noop } from '~/src/common/utils/function';
import { round } from '~/src/common/utils/number';
import {
  getCurrentOrdersQueryKey,
  getGetCartQueryKey,
} from '~/src/queries/api-ecom/generated/api-ecom';

/**
 * Erreur E_ECOM_01_0008 - Commande qui n'est plus complétable
 * Cutoff de complétion de commande atteint ou commande en préparation
 */
export default {
  code: CartErrors.OrderNoMoreCompletable.code,
  handler: ({ store }: HandlerPayload) => {
    const { queryClient } = store || {};
    const currentCart = queryClient?.getQueryData<Cart>(getGetCartQueryKey());
    const { products, price, initialOrder, delivery } = currentCart || {};

    Tracker.sendEvent('Impossible add products modal viewed', {
      'order id': initialOrder?.id ?? '',
      'shipping slot': delivery?.timeSlot
        ? getTimeslotIntervalHourLabelInfos(delivery.timeSlot.from, delivery.timeSlot.to)
        : '',
      'total cart size': products?.length ?? 0,
      'total cart': round((price?.quotation.net ?? 0) / 100, 2),
    });

    // MAJ de la bannière des commandes en cours (texte spécifique quand la commande est complétable)
    void queryClient?.invalidateQueries(getCurrentOrdersQueryKey());

    showNiceModal('expired-complete-order-modal').catch(noop);
  },
};
