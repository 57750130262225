import { type PropsWithChildren, useState } from 'react';

import { ReadMoreContent, SeeMoreButton } from '~/src/common/components/ReadMore/layout';
import I18n from '~/src/common/services/I18n/I18n';
import Tracker from '~/src/common/services/Tracker';

type Props = PropsWithChildren<{ collapsedHeight?: number; initialCollapsed?: boolean }>;

export const ReadMore = ({ children, collapsedHeight = 117, initialCollapsed = true }: Props) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  // Mesure le contenu, check si le collapse est utile, et calcule la hauteur d'affichage
  const contentHeight = ref?.scrollHeight;
  const collapseEnabled = !!contentHeight && contentHeight > collapsedHeight;
  const height = collapseEnabled ? (collapsed ? collapsedHeight : contentHeight) : 'auto';

  // Calcul de la hauteur et de la durée de l'animation (clamp 0-750ms)
  const transitionHeight = (contentHeight ?? 0) - collapsedHeight;
  const transitionDuration = `${Math.max(0, Math.min(750, transitionHeight))}ms`;

  // Handle click toggle button
  const handleClickToggleButton = () => {
    setCollapsed(prev => !prev);
    Tracker.sendEvent(collapsed ? 'read more' : 'read less', {
      'current page path': document.location.pathname,
    });
  };

  return (
    <>
      <ReadMoreContent ref={setRef} style={{ height, transitionDuration }}>
        {children}
      </ReadMoreContent>
      {collapseEnabled ? (
        <SeeMoreButton type="button" onClick={handleClickToggleButton}>
          {collapsed ? I18n.t('read-more.see-more') : I18n.t('read-more.see-less')}{' '}
        </SeeMoreButton>
      ) : null}
    </>
  );
};
