import { DeliveryErrors } from '@keplr/api-ecom-errors';

import invalidTimeSlotHandler from '../orders/E_ECOM_08_0003';

/**
 * Erreur E_ECOM_12_0002 - Le créneau de livraison n'est plus disponible.
 * Le créneau de livraison est indisponible
 * Comportement: On invite l'utilisation sélectionner une adresse de livraison (si besoin) et un nouveau créneau
 * et on met à jour le panier si des données concernant ce dernier on été renvoyées dans la réponse
 */
export default {
  code: DeliveryErrors.TimeSlotExcluded.code,
  handler: invalidTimeSlotHandler.handler,
};
